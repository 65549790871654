// お問い合せ

import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import TextField from '@material-ui/core/TextField'

const Privacy = ({data}) => {
	const onSubmit = (data) => console.log(data)

	return (
		<>
			<SEO
				pagetitle='お問合せフォーム'
				pageDesc='このページは、相氣一進流のお問合せフォームです。'
			/>
			<Layout>
				<div className='contactForm'>
					<h1>お問い合せ</h1>
					<p>
						道場に関するお問い合わせは、電話、メールにて受け付けております。お問い合わせいただきました内容は、お客様の同意なく第三者に開示・提供することはございません。
					</p>

					<p>
						<strong>電話番号：</strong>
						<a href='tel:0367506530'>03-6750-6530</a>
					</p>
					<p>
						<strong>メールアドレス：</strong>
						<a href='mailto:aikiishinryu21@gmail.com'>
							aikiishinryu21@gmail.com
						</a>
					</p>

					{/* <p>
						稽古のお問い合わせに関しましては電話またはこちらのフォームから受けつけております。
						お問い合わせいただきました内容は、お客様の同意なく第三者に開示・提供することはございません。
					</p> */}
					{/* <form
						name='contact'
						method='POST'
						action='/thanks'
						data-netlify-honeypot='bot-field'
						data-netlify='true'
						// data-netlify-recaptcha='true'
					>
						<input type='hidden' name='form-name' value='contact' />
						<input type='hidden' name='bot-field' />
						<TextField label='お名前(必須)' name='name' type='text' require />
						<TextField
							label='メールアドレス（必須）'
							name='email'
							type='email'
							require
						/>
						<TextField
							label='お問合せ内容(400文字以内)(必須)'
							multiline
							name='message'
							require
						/>
						<div data-netlify-recaptcha='true'></div>
						<button type='submit'>送信</button>
					</form> */}
				</div>
			</Layout>
		</>
	)
}

export default Privacy
